import React from 'react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

import Products from './Products';

export default function Dashboard({ authenticated }) {
  return (
    <>
      <Typography variant="h5" gutterBottom>
        Dawntech - Produtos
      </Typography>

      <Paper variant="outlined" sx={{ my: 2, p: 2 }}>
        <Typography variant="h6">
          Informações do usuário
        </Typography>
        <Typography variant="body2">
          <b>Email</b>: { authenticated.email }
          <br/>
          <b>ID</b>: { authenticated.uid }
        </Typography>
      </Paper>


      <Products user={authenticated?.uid}/>
    </>
  );
}
