import { auth, firestore } from '../utils/firebase';
import { collection, query, where, getDocs, doc, getDoc, updateDoc } from 'firebase/firestore';
import { EmailAuthProvider  } from 'firebase/auth';

import { signInWithEmailAndPassword, signOut as signOutFirebase, updatePassword, reauthenticateWithCredential } from 'firebase/auth';

class FirebaseService {
  static signIn(email, password) {
    return signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        localStorage.setItem('user', JSON.stringify(userCredential.user));
        return userCredential;
      });
  }

  static signOut() {
    return signOutFirebase(auth).then(() => {
      localStorage.removeItem('user');
    }).catch((error) => {
      console.error(error);
    });
  }

  static checkAuthentication() {
    return auth.currentUser;
  }

  static setUserPassword(oldPassword, newPassword) {
    const user = auth.currentUser;

    const credential = EmailAuthProvider.credential(
      user.email, 
      oldPassword
    );

    return reauthenticateWithCredential(user, credential)
      .then(() => updatePassword(user, newPassword));
  }

  static async getClientInfo(id) {
    try {
      return this.getDocsFrom('clients', 'user', id);
    } catch (e) {
      console.error('Error getting document: ', e);
    }
  }

  static async getDocsFrom(coll, attribute, value, firstDocOnly = true) {
    try {
      const q = query(collection(firestore, coll), where(attribute, '==', value));
      const docSnap = await getDocs(q);
      
      if (!docSnap.empty) {
        if (firstDocOnly) {
          return { id: docSnap.docs[0].id, ...docSnap.docs[0].data() };
        }
        return docSnap.docs.map(d => ({ id: d.id, ...d.data()}));
      }
    } catch (e) {
      console.error('Error getting document: ', e);
    }
  }

  static async getDocument(coll, id) {
    try {
      const docRef = doc(firestore, coll, id);
      const docSnap = await getDoc(docRef);
      
      if (docSnap.exists()) {
        return { id: docSnap.id, ...docSnap.data() };
      }
    } catch (e) {
      console.error(e);
    }
  }

  static updateDocument(coll, id, data) {
    try {
      const docRef = doc(firestore, coll, id);
      return updateDoc(docRef, data);
    } catch (e) {
      console.error(e);
    }
  }
}


export default FirebaseService;
