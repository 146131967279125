import React from 'react';
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';

import RedefinePassword from './RedefinePassword';
import FirebaseService from '../services/firebase';

export default function Configurations() {
  return (
    <>
      <Box my={5}>
        <Typography variant="subtitle1" gutterBottom>
        Redefinir senha
        </Typography>

        <RedefinePassword setUserPassword={FirebaseService.setUserPassword}/>
      </Box>
    </>
  );
}
