import React, { useState } from 'react';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';

import { useSnackbar } from 'notistack';

import FirebaseService from '../services/firebase';

export default function Products({ user }) {
  const [userProducts, setUserProducts] = useState([]);
  const [selected, setSelected] = useState();
  const [loading, setLoading] = useState(false);

  let [form, setForm] = React.useState({});
  
  const { enqueueSnackbar } = useSnackbar();

  const initSelected = (up) => {
    setSelected(up);

    const savedValues = JSON.parse(up.values);
    setForm(Object.fromEntries(up.product.inputs.map(i => [i, savedValues[i] ? savedValues[i] : ''])));
  };

  const submitForm = () => {
    setLoading(true);
    const values = JSON.stringify(form);

    // Salva no banco
    FirebaseService.updateDocument('user-products', selected.id, { values }).then(() => {
      // Salva nos produtos
      setSelected({ ...selected, ['values']: values });

      enqueueSnackbar('Alterações efetuadas com sucesso!', { variant: 'success' });
      setLoading(false);
    }, () => {
      enqueueSnackbar('Erro ao alterar valores.', { variant: 'error' });
      setLoading(false);
    });
  };

  const updateValue = (prop) => (e) => {
    setForm({ ...form, [prop]: e.target.value });
  };

  React.useEffect(async () => {
    if (user) {
      FirebaseService.getDocsFrom('user-products', 'user', user, false).then(async up => {
        if (!up) {
          setUserProducts([]);
        } else {
          const products = await Promise.all(up.map(_up => FirebaseService.getDocument('products', _up.product)));
          up.forEach((u, index) => {
            u.product = products[index];
          });
          setUserProducts(up);
        }
      });
    }
  }, []);

  return (
    <>
      { userProducts.length === 0 ?
        <Typography>
          Sem produtos registrados para o usuário.
        </Typography>
        :
        <>
          {
            userProducts.map(up => (
              <Paper elevation={1} key={up} sx={{ my: 2 }}>
                <List>
                  <ListItem
                    secondaryAction={
                      <>
                        <Button variant="contained" color="secondary" href={`${up.product.url}/?user_id=${user}`}>Acessar produto</Button>
                        <Button sx={{ m: 1 }} edge="end" variant="outlined" onClick={() => initSelected(up)}>Configurar</Button>
                      </>
                    }
                  >
                    <ListItemText primary={up.product.type}/>
                  </ListItem>
                </List>
              </Paper>
            ))
          }
        </>
      }

      { !selected ? <></> :
        <Paper variant="outlined" sx={{ mt: 5 }}>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', p: 2 }} component="form" onSubmit={(e) => { e.preventDefault(); submitForm(); }}>
            <Stack spacing={2}>
              <Typography>
                Selecionado: <b>{ selected.product.type }</b>
              </Typography>

              { 
                selected.product.inputs.map(input => (
                  <TextField key={input}
                    label={input}
                    id={input}
                    sx={{ m: 0, width: '50ch' }}
                    defaultValue={form[input]}
                    onChange={updateValue(input)}
                  />
                ))
              }

              <LoadingButton variant="contained" type="{'submit'}" disabled={loading} loading={loading}>
                Alterar valores
              </LoadingButton>

              <Button variant="contained" color="secondary" href={`${selected.product.url}/?user_id=${user}`}>
                Acessar produto
              </Button>
            </Stack>
          </Box>
        </Paper>
      }
    </>
  );
}
