import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth, setPersistence, browserLocalPersistence } from 'firebase/auth';

// import { getFirestore, where, query, collection, getDocs, addDoc } from 'firebase/firestore';

// import { Timestamp } from '@firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyDF3ynM92Z7Koq3iqT_RUBkvZ9Ulq57YKE',
  authDomain: 'dawntech-website.firebaseapp.com',
  projectId: 'dawntech-website',
  storageBucket: 'dawntech-website.appspot.com',
  messagingSenderId: '107195885524',
  appId: '1:107195885524:web:0c07b5a61b1679f99e9841',
  measurementId: 'G-8TN7P2X575'
};

export const app = initializeApp(firebaseConfig);
export const firestore = getFirestore(app);
export const auth = getAuth();

setPersistence(auth, browserLocalPersistence)
  .then(() => {});
