export function authenticationErrorMessage(error) {
  let message = '';

  switch (error.code) {
    case 'auth/invalid-credential':
    case 'auth/wrong-password':
      message = 'Erro ao autenticar. Credencial informada inválida.';
      break;
    default:
      message = 'Erro ao autenticar. Tente novamente mais tarde.';
  }

  return message;
}
