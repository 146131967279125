import React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';

import { useSnackbar } from 'notistack';
import { authenticationErrorMessage } from '../../auth/authMethods';

const LoginForm = ({ signIn }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [form, setForm] = React.useState({
    email: '', email_valid: false,
    password: '', password_valid: false,
    showPassword: false,
  });

  const [loading, setLoading] = React.useState(false);

  const handleChange = (prop) => (e) => {
    setForm({ ...form, [prop]: e.target.value, [`${prop}_valid`]: e.target.value.length > 0 });
  };

  const handleClickShowPassword = () => {
    setForm({
      ...form,
      showPassword: !form.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const submitForm = () => {
    setLoading(true);

    signIn(form.email, form.password).then(
      () => {},
      (err) => {
        enqueueSnackbar(authenticationErrorMessage(err), { variant: 'error' });
      }
    ).then(() => setLoading(false));
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', flexWrap: 'wrap' }} component="form" onSubmit={(e) => { e.preventDefault(); submitForm(); }}>
        <Stack spacing={4}>
          <Typography
            sx={{
              textTransform: 'uppercase',
              fontWeight: 'medium',
            }}
            gutterBottom
            color={'textSecondary'}
          >
          Login
          </Typography>
        
          <TextField
            label="Email"
            id="email"
            sx={{ m: 0, width: '50ch' }}
            onChange={handleChange('email')}
          />

          <FormControl sx={{ m: 1, width: '50ch' }} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">Senha</InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={form.showPassword ? 'text' : 'password'}
              value={form.password}
              onChange={handleChange('password')}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {form.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </FormControl>
          
          <LoadingButton variant="contained" type="{'submit'}" disabled={!(form.password_valid && form.email_valid)} loading={loading}>
            Entrar
          </LoadingButton>
        </Stack>
      </Box>
    </Box>
  );
};

export default LoginForm;
