import React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';

import { useSnackbar } from 'notistack';
import { authenticationErrorMessage } from '../auth/authMethods';

function RedefinePassword({ setUserPassword }) {
  const { enqueueSnackbar } = useSnackbar();
  
  const [form, setForm] = React.useState({
    oldpassword: '',
    password: '',
    passwordConfirm: ''
  });

  const [passwordValid, setPasswordValidity] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const handleChange = (prop) => (e) => {
    setForm({ ...form, [prop]: e.target.value });
  };
  
  React.useEffect(() => {
    const valid = (form.password.length >= 6) && (form.password === form.passwordConfirm) && (form.oldpassword.length >= 6);
    setPasswordValidity(valid);
  }, [form]);

  const submitForm = () => {
    setLoading(true);

    setUserPassword(form.oldpassword, form.password).then(() => {
      enqueueSnackbar('Alteração de senha efetuada com sucesso!', { variant: 'success' });
      setLoading(false);
    }, (e) => {
      enqueueSnackbar(authenticationErrorMessage(e), { variant: 'error' });
      setLoading(false);
    });
  };

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap' }} component="form" onSubmit={(e) => { e.preventDefault(); submitForm(); }}>
      <Stack spacing={2}>
        <TextField
          label="Senha antiga"
          id="oldpassword"
          sx={{ m: 0, width: '50ch' }}
          onChange={handleChange('oldpassword')}
        />

        <TextField
          label="Nova senha"
          id="password"
          sx={{ m: 0, width: '50ch' }}
          onChange={handleChange('password')}
        />

        <TextField
          label="Confirmação da nova senha"
          id="passwordConfirm"
          sx={{ m: 0, width: '50ch' }}
          onChange={handleChange('passwordConfirm')}
        />
          
        <LoadingButton variant="contained" type="{'submit'}" disabled={!passwordValid} loading={loading}>
          Confirmar
        </LoadingButton>
      </Stack>
    </Box>
  );
}

export default RedefinePassword;
