/*eslint-disable*/
import React, { useState } from "react";
import PropTypes from 'prop-types';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';

import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
import HomeIcon from '@mui/icons-material/Home';

import { Link } from "react-router-dom";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import LoginForm from './Login/LoginForm';

function Menu({ component, authMethods }) {
  const links = [
    {
      text: 'Home',
      link: '/',
      icon: <HomeIcon />
    },
    {
      text: 'Configurações',
      link: '/config',
      icon: <SettingsIcon />
    }
  ];

  return (
    <Box
      sx={{ width: 250 }}
      role="presentation"
    >
      <List>
        {links.map(({ text, link, icon }, index) => (
          <ListItem button key={text} component={Link} to={link}>
            <ListItemIcon>
              {icon}
            </ListItemIcon>
            <ListItemText primary={text}/>
          </ListItem>
        ))}

        <ListItem button onClick={() => authMethods.signOut()}>
          <ListItemIcon>
            <LogoutIcon/>
          </ListItemIcon>
          <ListItemText primary={'Sair'}/>
        </ListItem>
      </List>
    </Box>
  );
}


function AuthenticatedLayout({ subcomponent: Subcomponent, authenticated, authMethods, ...rest }) {
  if (!authenticated) {
    return (
      <Box
        position={'relative'}
        minHeight={'calc(100vh - 247px)'}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
        height={'100%'}
      >
        <LoginForm signIn={authMethods.signIn} />
      </Box>
    );
  }

  return (
    <Box
      position={'relative'}
      minHeight={'calc(100vh - 247px)'}
      display={'flex'}
      justifyContent={'center'}
      height={'100%'}
    >
      <Container maxWidth={'xl'}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Menu authMethods={authMethods} />
          </Grid>
          <Grid item xs={9}>
            <Box mb={2}>
              <Card>
                <CardContent>
                  <Box m={5}>
                    <Subcomponent authenticated={authenticated} {...rest}/>
                  </Box>
                </CardContent>                
              </Card>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

AuthenticatedLayout.propTypes = {
  subcomponent: PropTypes.elementType
};

export default AuthenticatedLayout;
