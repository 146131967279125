import * as React from 'react';
import { Redirect, Route } from 'react-router';

export function PrivateRoute({ authenticated, redirectTo, render, ...rest}) {
  if (!redirectTo) {
    redirectTo = '/';
  }
  
  return (
    <Route
      {...rest}
      render={(props) => authenticated
        ? render(props)
        : <Redirect to={{pathname: redirectTo, state: {from: props.location}}} />}
    />
  );
}
