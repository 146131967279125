import React, { useState } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Routes from './routes';

import 'scss/normalize.scss';
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'aos/dist/aos.css';

import 'scss/slick-slider.scss';
import 'scss/theme.scss';

import FirebaseService from './services/firebase';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

const browserHistory = createBrowserHistory();


const App = () => {
  const [authenticated, setAuthenticated] = useState();
  
  const authMethods = {
    signIn(email, password) {
      return FirebaseService.signIn(email, password).then((userCredential) => {
        setAuthenticated(userCredential.user);

        return userCredential.user;
      });
    },
  
    signOut() {
      return FirebaseService.signOut().then(() => {
        setAuthenticated(FirebaseService.checkAuthentication());
      });
    },
  
    setInitialAuth() {
      setTimeout(() => {
        setAuthenticated(FirebaseService.checkAuthentication());
      }, 1000);
    },
  };


  React.useEffect(() => {
    authMethods.setInitialAuth();
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Router history={browserHistory}>
        <Routes authenticated={authenticated} authMethods={authMethods}/>
      </Router>
    </LocalizationProvider>
  );
};

export default App;
