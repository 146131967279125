import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import WithLayout from 'WithLayout';

import { Main as MainLayout } from '@dawntech/react-layout';

import '@fortawesome/fontawesome-free/css/all.min.css';

import { PrivateRoute } from './privateRoute';
import AuthenticatedLayout from './views/AuthenticatedLayout';
import Dashboard from './views/Dashboard';
import Configurations from './views/Configurations';

const Routes = ({ authenticated, authMethods }) => {
  return (
    <Switch>
      <Route path={['/']} exact render={(matchProps) => (
        <WithLayout
          {...matchProps}
          authenticated={authenticated}
          authMethods={authMethods}
          component={AuthenticatedLayout}
          subcomponent={Dashboard}
          layout={MainLayout}
        />
      )} authenticated={authenticated} />

      <PrivateRoute path='/config' exact render={(matchProps) => (
        <WithLayout
          {...matchProps}
          authenticated={authenticated}
          authMethods={authMethods}
          component={AuthenticatedLayout}
          subcomponent={Configurations}
          layout={MainLayout}
        />
      )} authenticated={authenticated} />

      <Redirect to="/page-not-found" />
    </Switch>
  );
};

export default Routes;
